import React from 'react';
import * as ReactBootstrap from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import '../App.css';

export default class OurServices extends React.Component {
    render() {
        return (
            <div>
                
                <figure className="position-relative">
                    <ReactBootstrap.Image src="oilrig-banner.jpg" style={{"height":"200px", "width":"100%"}} className="img-fluid" />
                    
                    <figcaption className="text-center">Our Services
                    </figcaption>
                </figure>
                
                <ReactBootstrap.Container className="mb-4">

                <ReactBootstrap.Row  className="mt-4">
                    <ReactBootstrap.Col sm={12} className="text-center bg-secondary">
                        <h3  id="ourservices">What we do</h3>
                        <p>ARCS Marine Services strongly believes in the ideology of investing in people, processes and technology.
                        Over the years we have shaped a business philosophy where partnerships, service quality, consistency 
                        and above all customer satisfaction are paramount.</p>
                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                

                  <ReactBootstrap.Row className="mt-4">

                    <ReactBootstrap.Col sm={4} >
                        <img src="provisionsp.png" alt="provision img" className="img-fluid"/>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col sm={8} className="content-ceneter bg-secondary">
                        <h3 id="ourservices" >Provisions and other ship supplies</h3>
                        <p>
                            At Siri Agro Exports, we supply wide range of provisions for onshore and offshore facilities. Our provision 
                            supplies include dry and wet ration for all the on ship and off ship caterers. We are specialized in 
                            supplying fresh vegetables and fruits (direct from farmers) and frozen foods. We buy and stock 
                            intercontinental packaged foods to address the cravings for foreign personnel on board the ship. These 
                            foods include South East-Asian, European, Chinese, Korean and Japanese cuisines.
                        </p>

                        <p>
                            Our other deck supplies include deck and engine consumables. Under Raghuram Marine services we 
                            supply most comprehensive ranges of technical, industrial and navigation products for the marine market, 
                            ranging from electronic equipment, ropes, wires, loose paints and industrial tools, operating spares, and 
                            instruments. 
                        </p>
                    
                        <p >
                            With a motto of swift delivery for emergency supplies we stock necessary items at our warehouse 
                            facilities and service our customer on demand. ARCS Marine Services is aimed to be one stop shop for 
                            deck stores and stocks. 
                        </p>
                         

                    </ReactBootstrap.Col>
                    
                  </ReactBootstrap.Row>

                <ReactBootstrap.Row  className="mt-4">
                    <ReactBootstrap.Col className="bg-secondary" sm={8}>
                    <h3 className="text-center" id="ourservices">Technical Supplies & Engineering Services</h3>
                        <p>
                            We offer a wide range of authentic products and services to our customers in the marine, oil & gas and 
                            other process industries. 
                        </p>
                    
                        <p >
                            Our technical supplies include prefabricated equipment like Ladders, Storage Racks, Pallets, Railings, 
                            cargo baskets etc. We are highly competitive at sourcing equipment both locally and globally, owing to 
                            which our supply services are not limited to agency sales.
                        </p>

                        <p>
                            Whenever possible, we purchase directly from the manufacturer and ensure swift delivery to our 
                            customers. Customer satisfaction is of utmost priority to us, and hence we provide a competitive price 
                            with complete after-sales services Technical Supplies & Engineering Services
                        </p>
                        <p>
                            The engineering and mechanical services division represents the original core business of the company 
                            and continues to be a vital part of our business. This division offers services ranging from ship repair 
                            services, sea fastening, fabrication, maintenance of plant and machinery, material handling equipment etc
                        </p>
                        <h5 >Our core engineering services include: </h5>
                        <ol >
                            <li>Design & Fabrication</li>
                            <li>Blasting & Painting</li>
                            <li>Piping</li>
                            <li>Scaffolding installation and un-installation</li>
                            <li>Maintenance & Repair activities</li>
                            <li>Onshore & Offshore shipyard services</li>
                        </ol>

                        <p>We have a record of high-quality construction for the Oil & Gas and shipping industry.</p>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col sm={4} className = "technical">
                    <img src="repair.jpg" alt="technical supplies img" className="img-fluid "/>
                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>

                <ReactBootstrap.Row  className="mt-4">
                    <ReactBootstrap.Col sm={4} >
                        <img src="manpower.png" alt="manpower img" className="img-fluid"/>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col className="manpower bg-secondary" sm={8}>
                    <h3 className="text-center" id="ourservices">Manpower</h3>
                        <p>
                        Based out in Kakinada, our contracted manpower are specialized in the on-site fabrication and erection of 
                        mechanical equipment and related mechanical works, on-shore and off-shore fabrication, installation work 
                        and Shutdown work. Our manpower operations are handled by a top-class industry partner with highly 
                        skilled and efficient people. With a team of 30 personnel of different trades - Welders, Fitters, Plumbers, 
                        Machinists, Electricians, Helpers and machine operators, we also have a managerial team effectively 
                        supervising the works and manpower. 
                        </p>
                    
                        <p >
                        Our staff are well- disciplined and firmly trained on Industry HSE Norms.
                        </p>

                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>

                <ReactBootstrap.Row  className="mt-4">
                    <ReactBootstrap.Col sm={8} className="bg-secondary">
                    <h3 className="text-center" id="ourservices">One stop shop for all your safety needs</h3>
                        <p>
                            Devoted to offer a complete safety to our customers, ARCS Marine Services is a front runner supplier, 
                            dealer, and distributor of industrial safety equipment for head protection, eye protection, face protection, 
                            ear protection, respiratory protection, foot protection, fall protection, hand protection etc. We are dealing 
                            in a wide assortment of industrial, construction, traffic & road safety devices, safety shower & eye wash, 
                            first aid, wind indicator and protective clothing to meet clients' distinct requirements. 
                        </p>
                    
                        <p >
                            We are backed by experienced market leaders such as 3M, Venus, Karam, Udyogi, MallCom, Honeywell, 
                            Midas, Fortune, Metro, ESAB, Stanley, Kimberly Clark, Lockouts & Tagouts which are providing diverse 
                            PPE to cater all needs of our customers. All our products are certified from various National and 
                            International institutions to ensure its Integrity.
                        </p>

                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col sm={4}>
                        <img src="safety.jpg" alt="safety img" className="img-fluid"/>
                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>

                <ReactBootstrap.Row  className="mt-4">
                    <ReactBootstrap.Col sm={4} >
                        <img src="transport.png" alt="transport img" className="img-fluid"/>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col className="logistics bg-secondary" sm={8}>
                    <h3 className="text-center" id="ourservices">Logistics & Transport Facilities</h3>
                        <p>
                        We provide complete logistics support and asset services to marine, oil & gas and other process industry
customers understanding the needs and condition of roads and work. We offer both chauffer driven and
self-drive services with our fleet of vehicles.
                        </p>
                    <h5>Our fleet consists of –</h5>
                        <ol>
                            <li>Cranes as per Rated Capacity</li>
                            <li>Trailers</li>
                            <li>Forklifts (various tonnage loads)</li>
                            <li>Cargo and containers (various sizes)</li>
                            <li>Heavy load carrying vehicles (DCM, Pick-up Van)</li>
                        </ol>

                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>

                <ReactBootstrap.Row  className="mt-4">
                    <ReactBootstrap.Col className="bg-secondary" sm={8}>
                    <h3 className="text-center" id="ourservices">Rentals</h3>
                        <p>
                        We shall rent out the following machines/equipment on multi-season contracts. 
                        </p>
                        <ol>
                            <li>Traveller Buses for Off-Shore services</li>
                            <li>Premium Sedan, SUV and MUV class vehicles</li>
                            <li>Scaffolding material</li>
                            <li>Machinery like compressors, generators, pressure pumps, slurry pumps, water pumps etc.</li>
                        </ol>

                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col sm={4} >
                        <img src="rental.jpg" alt="rental img" className="img-fluid"/>
                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>

                <ReactBootstrap.Row  className="mt-4">
                <ReactBootstrap.Col sm={4} >
                        <img src="trading.jpg" alt="trading img" className="img-fluid"/>
                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col className="bg-secondary" sm={8}>
                    <h3 className="text-center" id="ourservices">Trading</h3>
                        <p>
                            India is strategically located on the world's shipping routes with a coastline of approximately 7,517 KM. 
                            As a registered MSME unit trader under Indian government scheme, we aim to leverage various fiscal and 
                            non-fiscal incentives offered to MSMEs that develop, maintain, and operate ports, inland waterways and 
                            shipbuilding in India.
                        </p>
                    <p>We offer to trade Industrial gases for refilling and renting, steel which includes plates, shapes, pipes & 
hollow structural tubes, other industrial machinery and scrap.</p>

                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>

                <ReactBootstrap.Row  className="mt-4">
                    <ReactBootstrap.Col className="bg-secondary">
                    <h3 className="text-center" id="ourservices">Facilities</h3>
                    <h5>Our facilities include:</h5>
                        <ol>
                            <li>A corporate office with a workspace for up to 25 back-end employees</li>
                            <li>A front-end office with a capacity seating for 10 employees</li>
                            <li>A Provisions warehouse with separate allocated spaces for dry and wet supplies. Chiller units to
                            keep frozen foods safe. </li>
                            <li>A safe and secured yard – store/warehouse. </li>
                            <li>A Mechanical workshop perfect enough to handle a mid-sized fabrication, blasting & painting 
                            jobs.</li>
                            <li>A Guest house and a cook to accommodate our privileged guests. </li>
                        </ol>

                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>

                </ReactBootstrap.Container>
            </div>
        )
    }
}
