import React from 'react';
import * as ReactBootstrap from "react-bootstrap";
import '../App.css';

export default class About extends React.Component {
    render() {
        return (
            <div>
              

              <figure className="position-relative">
                    <ReactBootstrap.Image src="oilrig-banner.jpg" style={{"height":"200px", "width":"100%"}} className="img-fluid" />
                    
                    <figcaption className="text-center">About Us
                    </figcaption>
                </figure>
                
              <ReactBootstrap.Container className="mb-4">

                <ReactBootstrap.Row  className="mt-4">
                    <ReactBootstrap.Col sm={12} className="text-center ">
                        <p>ARCS Marine Services is a subsidiary company of a three-generation old company Siri Agro 
                      Exports having a global presence in trading agro products. The trade-off grew with flying colours and it 
                      became the traditional family business in the Godavari Districts of Andhra Pradesh, India. Siri Agri 
                      Exports is the official vendor for McDerMott, Kakinada Sea Ports Ltd., Reliance Industries, ONGC and subcontractors like Sodexo and OCS.</p>
                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                

                  <ReactBootstrap.Row className="mt-4">
                    <ReactBootstrap.Col sm={8} className="content-ceneter ">
                        <h3 >Our Goal</h3>
                        <p>
                        Our goal is to work closely with our customers and exceed their expectations. 
                        We know that high quality products and service are best delivered through passionate people. 
                        The dedication to fabrication standards, quality control and transportation 
                        all show the personal responsibility our team takes for delivering great projects.
                        </p>
                         

                    </ReactBootstrap.Col>
                    <ReactBootstrap.Col sm={4} >
                        <img src="about us.jpg" alt="about us img" />
                    </ReactBootstrap.Col>
                  </ReactBootstrap.Row>

                <ReactBootstrap.Row  className="mt-4">
                    <ReactBootstrap.Col className="">
                      <h3>Our Vision</h3>
                        <p>
                        To become the trusted partner of our clients, investing in long-term collaborative relationships with employees, clients, and suppliers alike.
                        </p>
                      
                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>

                <ReactBootstrap.Row  className="mt-4">
                    <ReactBootstrap.Col className="">
                    <h3>Our Quality Policy</h3>
                        <p >
                        We know that the relationship between the organisation and suppliers is interdependent and hence we 
                        aim to provide a mutually beneficial relationship that provides value to both the parties. To implement 
                        this policy, ARCS Marine Services will soon establish a Quality Management System in line with the 
                        requirements of the ISO 9001:2015 Standard or equivalent. 
                        </p>
                    
                    <h5>Our Target Quality Management System aims to:</h5>
                      <ol>
                        <li>Meet the requirements of interested parties and our social, environmental, charitable, regulatory, 
                          and legislative responsibilities
                        </li>
                        <li>Provide necessary resources and ensure that responsibilities are determined and communicated 
                        throughout the organisation.
                        </li>
                        <li> Establish business and quality objectives, which are reviewed periodically through the 
                          management review process.
                        </li>
                        <li>Seek feedback from clients and carry out actions in accordance to the client’s requirements
                        </li>
                      </ol>

                      <p>At ARCS Marine Services, we pledged to implement an Internal Audit Program to ensure that the ongoing 
                        suitability and conformity of the Quality Management System is assured. Keeping this in mind, it has the 
                        full support of Executive Team, Management Team and Staff</p>
                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>

                <ReactBootstrap.Row  className="mt-4">
                    <ReactBootstrap.Col className="">
                      <h3>Our HSE Policy</h3>
                        <p>
                          The HSE (Health, Safety and Environment) Policy of ARCS Marine Services defines the principles by which 
                          we conduct our operations in a manner that protects the health and safety of our employees, as well as 
                          our clients, contractors and other relevant third parties. We also apply the same principles to conserve 
                          our environment.
                        </p>
                        <p>
                          ARCS Marine Services adheres to the HSE management system in compliance with all local, national, and 
                          international laws and regulations.
                        </p>

                        <h5>Health Policy @ ARCS</h5>

                        <ol>
                          <li>Takes all measures necessary to maintain optimal working conditions.</li>
                          <li>Monitors all work-related activities to ensure the well-being of our employees, contractors, and 
                            relevant third parties.
                          </li>
                          <li>Promotes awareness of, and provides education in, health-related subjects.</li>
                        </ol>

                        <h5>Safety @ ARCS</h5>

                        <ol>
                          <li>Takes necessary measures to prevent work-related injuries.</li>
                          <li>Trains our employees and contractors to be aware of any potential risks to personal safety on the 
                            job.
                          </li>
                          <li>Grants our employees and contractors the option to suspend their duties and re-evaluate their 
                            safety should they perceive any potential risks.</li>
                        </ol>

                        <h5>Environment</h5>

                        <ol>
                          <li>Ensures that all employees work productively to conserve our environment.</li>
                          <li>Makes every effort to minimize compromising our environment, such as pollution and 
                            contamination.
                          </li>
                        </ol>

                        <p>ARCS Marine Services is committed to both maintaining and improving our HSE system on a regular 
                        basis</p>

                        <h3>Our Facilities</h3>
                        <p>We operate businesses from couple of facilities depending upon the vicinity of our end customers. Our 
                          registered office is based out in Rajahmundry where all the sales, engineering and logistics operations 
                          happen from Kakinada. Our 5000 square feet go-down cum engineering facility caters to majority of dayto-day jobs. Our contracted mechanical workshop is equipped with complete durable setup for handing 
                          activities like fabrication, blasting and painting chambers and warehouse.</p>
                    </ReactBootstrap.Col>
                </ReactBootstrap.Row>
                
                <h3 className="text-center mt-4">Our Clients</h3>
                
                <ReactBootstrap.Row className="mt-4">
                <ReactBootstrap.Col sm={4} className="text-center">
                  <ReactBootstrap.Image src="hp.png" rounded className="img-fluids client-imgs" />
                </ReactBootstrap.Col>
                
                <ReactBootstrap.Col sm={4} className="text-center">
                  <ReactBootstrap.Image src="reliancel.jpeg" rounded className="img-fluids client-imgs" />
                </ReactBootstrap.Col>
                <ReactBootstrap.Col sm={4} className="text-center">
                  <ReactBootstrap.Image src="ongc.jpg" rounded className="img-fluids client-imgs" />
                </ReactBootstrap.Col>
                
            </ReactBootstrap.Row>

            <ReactBootstrap.Row className="mt-4" >
                
                <ReactBootstrap.Col sm={4} className="text-center">
                  <ReactBootstrap.Image src="aban.jfif" rounded className="img-fluids client-imgs" />
                </ReactBootstrap.Col>
                <ReactBootstrap.Col sm={4} className="text-center">
                  <ReactBootstrap.Image src="mcdermott.jpg" rounded className="img-fluids client-imgs" />
                </ReactBootstrap.Col>
                <ReactBootstrap.Col sm={4} className="text-center">
                  <ReactBootstrap.Image src="vizag-steel.png" rounded className="img-fluids client-imgs" />
                </ReactBootstrap.Col>
                
            </ReactBootstrap.Row>

            <ReactBootstrap.Row className="mt-4">
                
                <ReactBootstrap.Col sm={4} className="text-center">
                  <ReactBootstrap.Image src="abb.png" rounded className="img-fluids client-imgs" />
                </ReactBootstrap.Col>
                <ReactBootstrap.Col sm={4} className="text-center">
                  <ReactBootstrap.Image src="ocs.jpg" rounded className="img-fluids client-imgs" />
                </ReactBootstrap.Col>
                <ReactBootstrap.Col sm={4} className="text-center">
                  <ReactBootstrap.Image src="rotex.jfif" rounded className="img-fluids client-imgs" />
                </ReactBootstrap.Col>
                
            </ReactBootstrap.Row>
              </ReactBootstrap.Container>
            </div>  
        )
    }
}