import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter as Router, Link, NavLink, Route } from "react-router-dom";
import * as ReactBootstrap from "react-bootstrap";

import Home from "./components/home";
import About from "./components/about";
import OurServices from "./components/ourservices";
import Contact from "./components/contact";

// import logo from "./logo.svg";
class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <ReactBootstrap.Navbar className="container" collapseOnSelect expand="lg" variant="light">
            <Link to="/">
              <img src="arcs logo 1.png" alt="Logo" className="logo" />
            </Link>
            <ReactBootstrap.Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <ReactBootstrap.Navbar.Collapse>
              <ReactBootstrap.Nav className="ml-auto">
                <NavLink to="/"  activeClassName="active"  exact={true} className="nav-link inactive">Home</NavLink>
                <NavLink to="/aboutus"  className="nav-link">About Us</NavLink>
                <NavLink to="/ourservices"  className="nav-link">Our Services</NavLink>
                <NavLink to="/contactus"  className="nav-link">Contact Us</NavLink>
              </ReactBootstrap.Nav>
            </ReactBootstrap.Navbar.Collapse>
          </ReactBootstrap.Navbar>
        
          <Route defaultActiveKey="/home" path="/" exact component={Home} />
          <Route path="/aboutus" exact component={About} />
          <Route path="/ourservices" component={OurServices} />
          <Route path="/contactus" component={Contact} />

          <div className="bg-dark pt-4 pb-4">
            <div className="container">
              <p className="text-center color">© 2021 ARCS Marine Services</p>
            </div>
          </div>
        </div>
      </Router>
    );
  }
}


export default App;


