import React from 'react';
import emailjs from "emailjs-com";
import * as ReactBootstrap from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faEnvelopeSquare, faMapMarker } from '@fortawesome/free-solid-svg-icons'

import '../App.css';


export default class Contact extends React.Component {

    state = {
        name: '',
        email: '',
        mobile: '',
        subject: '',
        message: '',
      }
    handleSubmit(e) {
        e.preventDefault()
        const { name, email, mobile, subject, message } = this.state
        let templateParams = {
          email: email,
          name: name,
          mobile: mobile,
          subject: subject,
          message: message,
         }
         emailjs.send(
          'service_lykwjsk',
          'template_npl9pjm',
           templateParams,
          'user_NwcdAmeE0bVqzw9EYq8Ez'
         )
         this.resetForm()
     }
    resetForm() {
        this.setState({
          name: '',
          email: '',
          mobile: '',
          subject: '',
          message: '',
        })
      }
    handleChange = (param, e) => {
        this.setState({ [param]: e.target.value })
      }
      
    render() {
        return (
            <div>
                <figure className="position-relative">
                    <ReactBootstrap.Image src="oilrig-banner.jpg" style={{"height":"200px", "width":"100%"}} className="img-fluid" />
                    
                    <figcaption className="text-center">Contact Us
                    </figcaption>
                </figure>
                <div className="container mb-4">
                    
                    
                    
                    <div className="card-deck pb-4">
                        <ReactBootstrap.Card className="com">
                            <FontAwesomeIcon icon={faMobileAlt} size="4x"  />
                            <h>Call Us</h>
                            <span className="font-weight-bold">0883 – 4001087</span>
                            <span  className="font-weight-bold">95422 62222</span>
                        </ReactBootstrap.Card>

                        <ReactBootstrap.Card className="com">
                            <FontAwesomeIcon icon={faEnvelopeSquare} size="4x"  />
                            <h>Mail Us</h>
                            <span  className="font-weight-bold">raghuram@arcs.co.in</span>
                            <span  className="font-weight-bold">rkkoti@arcs.co.in</span>
                            <span  className="font-weight-bold">a.raghuramchowdary@gmail.com</span>
                        </ReactBootstrap.Card>

                        <ReactBootstrap.Card className="com">
                            <FontAwesomeIcon icon={faMapMarker} size="4x"  />
                            <h>Location</h>
                            <span className="text-center font-weight-bold">Plot No: 89,
                                Opp Cairin Energy Schlumberger Road, Vakalpidi,
                                Kakinada, Andhra Pradesh 533005
                            </span>
                        </ReactBootstrap.Card>
                    </div>

                    <ReactBootstrap.Card className="contact-form">
                        <h3 className="text-center">Get in touch!</h3>
                        <p className="text-center">And we will get back to you ASAP.</p>
                        <form className="my-form text-center "  onSubmit={this.handleSubmit.bind(this)} >
                            <div className="p-4 m-4">
                                <div className="field">
                                  <input type="text" name="name" id="name" placeholder="Name" className="form-control" value={this.state.name} onChange={this.handleChange.bind(this, 'name')} required />
                                </div>
                                <div className="field pt-4">
                                  <input type="email" name="email" id="email" placeholder="Email" className="form-control" value={this.state.email} onChange={this.handleChange.bind(this, 'email')} required />
                                </div>
                                <div className="field pt-4">
                                  <input type="number" name="mobile" id="mobile" placeholder="Mobile" className="form-control" value={this.state.mobile} onChange={this.handleChange.bind(this, 'mobile')} required />
                                </div>
                                <div className="field pt-4">
                                  <input type="text" name="subject" id="subject" placeholder="Subject" className="form-control" value={this.state.subject} onChange={this.handleChange.bind(this, 'subject')} required />
                                </div>
                                <div className="field pt-4">
                                  <textarea rows="5" name="message" id="message" placeholder="Message" className="form-control" value={this.state.message} onChange={this.handleChange.bind(this, 'message')} required />
                                </div>
                                <div className="field pt-4">
                                    <button className="btn send" type="submit">Send</button>
                                </div>
                                
                            </div>
                        </form>
                    </ReactBootstrap.Card>
                </div>
            </div>
           
        );
    }
}
