// import logo from './logo.svg';
import '../App.css';
import * as ReactBootstrap from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <div className="App">
      

      <ReactBootstrap.Carousel>
        <ReactBootstrap.Carousel.Item>
          <img
            className="d-block w-100 img-fluid"
            src="banner1.jpg"
            alt="First slide"
          />
          <ReactBootstrap.Carousel.Caption>
            <h3>Design & Fabrication</h3>
            {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
          </ReactBootstrap.Carousel.Caption>
        </ReactBootstrap.Carousel.Item>
        <ReactBootstrap.Carousel.Item>
          <img
            className="d-block w-100 img-fluid"
            src="banner2.jpg"
            alt="Second slide"
          />

          <ReactBootstrap.Carousel.Caption>
            <h3>Blasting & Painting</h3>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          </ReactBootstrap.Carousel.Caption>
        </ReactBootstrap.Carousel.Item>
        <ReactBootstrap.Carousel.Item>
          <img
            className="d-block w-100 img-fluid"
            src="IoKrnw.jpg"
            alt="Third slide"
          />

          <ReactBootstrap.Carousel.Caption>
            <h3>Scaffolding installation and un-installation</h3>
            {/* <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
          </ReactBootstrap.Carousel.Caption>
        </ReactBootstrap.Carousel.Item>

        <ReactBootstrap.Carousel.Item>  
          <img
            className="d-block w-100 img-fluid"
            src="ship.jpg"
            alt="Forth slide"
          />

          <ReactBootstrap.Carousel.Caption>
            <h3>Maintenance & Repair activities</h3>
            {/* <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p> */}
          </ReactBootstrap.Carousel.Caption>
        </ReactBootstrap.Carousel.Item>
      </ReactBootstrap.Carousel>

    

      
    </div>
  );
}

export default App;
